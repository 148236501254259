<template>
<div>
    <el-table :data="ClassStudentsInfos" v-loading="loading">
        <el-table-column label="学生姓名" prop="StuName" align="center"></el-table-column>
        <el-table-column label="学号" prop="stu_id" align="center"></el-table-column>
        <el-table-column label="长编号" prop="stuNumber" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="rtime" align="center"></el-table-column>
        <el-table-column label="离校时间" prop="oyear"  align="center"></el-table-column>
        <el-table-column label="创建时裸眼视力"  align="center">
            <template slot-scope="scope">
                <div> <span >{{scope.row.ftleye?scope.row.ftleye:'暂无'}}</span>(L)</div>
                <div> <span >{{scope.row.ftreye?scope.row.ftreye:'暂无'}}</span>(R)</div>
          </template>
        </el-table-column>
                <el-table-column label="离校时裸眼视力"  align="center">
            <template slot-scope="scope">
                <div> <span >{{scope.row.ftleye?scope.row.ftleye:'暂无'}}</span>(L)</div>
                <div> <span >{{scope.row.ftreye?scope.row.ftreye:'暂无'}}</span>(R)</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="doDetail(scope.$index, scope.row)">查看详情
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    <div v-show="CountNum > 0">
    <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
    </el-pagination>
    </div>
</div>
</template>
<script>
import {
  classHistoryDetail
} from '@/api/SchoolApi.js'
import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters(['Status'])
  },
  data () {
    return {
      ClassStudentsInfos: [],
      loading: false, // 加载框
      pagesize: 12, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      cid: '',
      oyear: ''
    }
  },
  mounted () {
    this.cid = this.$route.params.cid
    this.oyear = this.$route.params.oyear
    this.ClassStudentsList(1) // 获取学生列表
  },
  methods: {
    ClassStudentsList (currpage) {
      this.loading = true
      const data = {
        page: currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        cid: this.cid,
        oyear: this.oyear
      }
      classHistoryDetail(data).then(res => {
        this.ClassStudentsInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    handleCurrentChange (val) {
      this.ClassStudentsList(val)
      this.currpage = val
    },
    // 查看详情
    doDetail (index, row) {
      this.$router.push({ // 页面跳转
        name: 'classStudentDetail', // 往这个页面跳转
        params: { // 跳转传递的参数
          StuID: row.StuUserID,
          StuName: row.StuName
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
